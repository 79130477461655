



















import { Vue, Component, Provide } from "vue-property-decorator";
import Nav from "@/components/header.vue";
@Component({
  components: {
    Nav,
  },
})
export default class AboutUs extends Vue {
  @Provide() add = "";
}
